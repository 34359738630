.container-section {
    display: flex;
    width: 100%;
    justify-content: center;
}

.container-flex {
    display: flex;
    width: 90%;
    align-items: center;
    margin-top: 5rem;
    height: 70vh;
}

.contact-form {
    display: flex;
    height: 100%;
    flex: 8;
    align-items: center;
    justify-content: center;
}

.contact-form form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
    appearance: none;
}

.contact-form input {
    font-family: 'Open sans', sans-serif;
    width: 80%;
    height: 4.5rem;
    border-radius: 5rem;
    appearance: none;
    background-color: rgba(255, 255, 255, 0.2);
    border: 0.2rem solid #892cdc;
    color: white;
    font-size: 2rem;
    padding: 0 2rem;
    margin-bottom: 2.5rem;
}

.contact-form input:focus {
    appearance: none;
    border: none;
    box-shadow: 0 0 2rem #892cdc;
    outline: 0.2rem solid #892cdc;
}

.contact-form textarea {
    resize: none;
    font-family: 'Open sans', sans-serif;
    width: 80%;
    border: 0.2rem solid #892cdc;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 2rem;
    font-size: 2rem;
    padding: 2rem;
    min-height: 12rem;
    max-height: 15rem;
    color: white;
    margin-bottom: 2.5rem;
}

.contact-form textarea:focus {
    appearance: none;
    border: none;
    box-shadow: 0 0 2rem #892cdc;
    outline: 0.2rem solid #892cdc;
}

.contact-form button {
    font-family: 'Open sans', sans-serif;
    color: #fff;
    font-size: 1.7rem;
    border-radius: 5rem;
    border: none;
    background: #fdfdfd00;
    transition: 100ms;
    box-shadow: 0 0 2.5rem #892cdc;
    padding: 1.5rem 2.5rem;
    appearance: none;
    text-decoration: none;
    font-size: 1.7rem;
    margin-top: 2rem;
  }
    
  .contact-form button:hover {
    transform: scale(1.1);
    transition: 500ms;
  }

  .contact-form button:active {
    transform: scale(0.9);
    transition: 300ms;
  }

.social-network {
    display: flex;
    background-color: rgba(255, 255, 255, 0);
    flex: 2;
    height: 100%;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}

.iconRs {
    width: 5rem;
    height: 5rem;
}

.iconRs:hover {
    width: 6rem;
    height: 6rem;
}

.iconRs:active {
    width: 5rem;
    height: 5rem;
}

.section-msg {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.section-msg h1 {
    font-family: 'Advent Pro', sans-serif;
    color: white;
    font-size: 3rem;
    margin-bottom: 2rem;
    max-width: 80%;
    text-align: center;
}

.section-msg h2 {
    font-family: 'Advent Pro', sans-serif;
    color: white;
    font-size: 2rem;
    max-width: 80%;
    text-align: center;
}

@media (max-width: 1000px) {

    
    .container-flex {
        margin-top: 3.5rem;
    }

    .contact-form input {
        height: 3.15rem;
        font-size: 1.4rem;
        padding: 0 1.4rem;
        margin-bottom: 1.75rem;
    }
    
    .contact-form textarea {
        font-size: 1.4rem;
        padding: 1.4rem;
        min-height: 8.4rem;
        max-height: 10.5rem;
        margin-bottom: 1.75rem;
    }
    
    .contact-form button {
        font-size: 1.19rem;
        padding: 1.05rem 1.75rem;
        margin-top: 1.4rem;
      }
    
    .iconRs {
        width: 3.5rem;
        height: 3.5rem;
    }
    
    .iconRs:hover {
        width: 4.2rem;
        height: 4.2rem;
    }
    
    .iconRs:active {
        width: 3.5rem;
        height: 3.5rem;
    }
    
    .section-msg h1 {
        font-size: 2.1rem;
        margin-bottom: 1.4rem;
    }
    
    .section-msg h2 {
        font-size: 1.4rem;
    }

    }