.back-to-home {
    display: block;
    position: fixed;
    width: 4.5rem;
    height: 4.5rem;
    bottom: 0;
    right: 0;
    margin: 0 5rem 5rem 0;
    border-radius: 50%;
    border: solid 0.2rem #892cdc;
    transition: all ease-in-out 100ms;
  }
  
  .back-to-home span {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .back-to-home:hover {
    transform: scale(1.1);
  }
  
  .back-to-home:active {
    transform: scale(1);
  }

  .back-to-home-tr {
    display: block;
    position: fixed;
    width: 4.5rem;
    height: 4.5rem;
    top: 0;
    right: 0;
    margin: 5rem 5rem 0rem 0;
    border-radius: 50%;
    border: solid 0.2rem #892cdc;
    transition: all ease-in-out 100ms;
    overflow: hidden;
    z-index: 1000;
  }
  
  .back-to-home-tr span {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .back-to-home-tr:hover {
    transform: scale(1.1);
  }
  
  .back-to-home-tr:active {
    transform: scale(1);
  }

  .back-to-home-bl {
    display: block;
    position: fixed;
    width: 4.5rem;
    height: 4.5rem;
    bottom: 0;
    left: 0;
    margin: 0rem 0rem 5rem 5rem;
    border-radius: 50%;
    border: solid 0.2rem #892cdc;
    transition: all ease-in-out 100ms;
    overflow: hidden;
    z-index: 1000;
  }
  
  .back-to-home-bl span {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .back-to-home-bl:hover {
    transform: scale(1.1);
  }
  
  .back-to-home-bl:active {
    transform: scale(1);
  }

  @media (max-width: 1000px) {

    .back-to-home {
      width: 4.5rem;
      height: 4.5rem;
      margin: 0 3.5rem 3.5rem 0;
      border: solid 0.2rem #892cdc;
    }
    
    .back-to-home-tr {
      width: 4.5rem;
      height: 4.5rem;
      margin: 3.5rem 3.5rem 0rem 0;
      border: solid 0.2rem #892cdc;
    }
  
    .back-to-home-bl {
      width: 4.5rem;
      height: 4.5rem;
      margin: 0rem 0rem 3.5rem 3.5rem;
      border: solid 0.2rem #892cdc;
    }

  }