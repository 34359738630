@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Architects+Daughter&family=El+Messiri:wght@400;500;600;700&family=Montserrat:wght@100;200;300;400;500;600;700;800&family=Open+Sans:wght@300;400;500;600;700;800&family=Philosopher:wght@400;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  font-size: 62.5%;
}

button, a {
  cursor: none;
}

section {
  height: 100vh;
  overflow: hidden;
  position: relative;
  cursor: none;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: -1;
}

.introcing {
  display: flex;
  flex-direction: column;
  z-index: 1;
  color: white;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}


.intro {
  text-align: left;
}

.intro h2 {
  font-family: 'Advent Pro', sans-serif;
  font-size: 5rem;
  color: white;
  -webkit-font-smoothing: antialiased;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  -webkit-text-stroke-width: 0.1rem;
}


.intro h1 {
  font-family: 'Advent Pro', sans-serif;
  color: white;
  font-size: 8rem;
  margin-left: -0.3rem;
}

.logoSp {
  position: absolute;
  margin: 8rem 0 0 12rem;
}

.hamburger {
  margin: 8rem 12rem 0 0;
  right: 0;
  position: absolute;
  width: 4.5rem;
  height: 2.5rem;
  transition: 500ms ease-in-out;
}

.hamburger span {
  display: block;
  position: absolute;
  height: 0.6rem;
  width: 0.6rem;
  border-radius: 50%;
  border: white solid 0.1rem;
  opacity: 1;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

p {
  max-width: 25%;
  font-family: 'Advent Pro', sans-serif;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 5rem;
}

.hamburger span:nth-child(1) {
  top: 0;
}

.hamburger span:nth-child(2) {
  top: 1.5rem;
}

.hamburger span:nth-child(3) {
  top: 3rem;
}

.hamburger span:nth-child(4) {
  top: 0;
  left: 1.5rem;
}

.hamburger span:nth-child(5) {
  top: 1.5rem;
  left: 1.5rem;
}

.hamburger span:nth-child(6) {
  top: 3rem;
  left: 1.5rem;
}

.hamburger span:nth-child(7) {
  top: 0;
  left: 3rem;
}

.hamburger span:nth-child(8) {
  top: 1.5rem;
  left: 3rem;
}

.hamburger span:nth-child(9) {
  top: 3rem;
  left: 3rem;
}

.hamburger span:nth-child(10) {
  opacity: 0;
  top: 1.5rem;
  height: 0.6rem;
  width: 3.5rem;
  border-radius: 5rem;
}

.hamburger span:nth-child(11) {
  opacity: 0;
  top: 0;
  left: 1.5rem;
  height: 3.5rem;
  width: 0.6rem;
  border-radius: 5rem;
}

.hamburger:hover span:nth-child(2),
.hamburger:hover span:nth-child(4),
.hamburger:hover span:nth-child(5),
.hamburger:hover span:nth-child(6),
.hamburger:hover span:nth-child(8) {
  opacity: 0;
}

.hamburger:hover span:nth-child(10) {
  opacity: 1;
}

.hamburger:hover span:nth-child(11) {
  opacity: 1;
}

.hamburger.open span:nth-child(1),
.hamburger.open span:nth-child(2),
.hamburger.open span:nth-child(3),
.hamburger.open span:nth-child(4),
.hamburger.open span:nth-child(5),
.hamburger.open span:nth-child(6),
.hamburger.open span:nth-child(7),
.hamburger.open span:nth-child(8),
.hamburger.open span:nth-child(9) {
  opacity: 0;
}

.hamburger.open span:nth-child(10) {
  opacity: 1;
  transform: rotate(45deg);
  top: 1.5rem;
  height: 0.6rem;
  width: 3.5rem;
  border-radius: 5rem;
}

.hamburger.open span:nth-child(11) {
  opacity: 1;
  transform: rotate(45deg);
  top: 0;
  left: 1.5rem;
  height: 3.5rem;
  width: 0.6rem;
  border-radius: 5rem;
}

.hamburger.open:hover span:nth-child(10) {
  transform: rotate(0deg);
}

.hamburger.open:hover span:nth-child(11) {
  transform: rotate(0deg);
}

.hamburger.open:hover span:nth-child(1),
.hamburger.open:hover span:nth-child(3),
.hamburger.open:hover span:nth-child(7),
.hamburger.open:hover span:nth-child(9) {
  opacity: 1;
}

.pages {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  margin-bottom: 11rem;
}

.sec-pages {
  display: flex;
  color: white;
  margin: 0 2rem;
  flex-direction: row;
  align-items: center;
  text-align: center;
}

.sec-pages h3 {
  color: white;
  margin: 0 2rem;
  font-family: 'Advent Pro', sans-serif;
  font-size: 2rem;
  position: relative;
}

.sec-pages h3::after {
  content: "";
  position: absolute;
  bottom: -25%;
  left: 0;
  width: 0;
  height: 0.25rem;
  background-color: white;
  transition: width 0.3s ease;
}

.sec-pages h3:hover::after {
  width: 100%;
}

.banders {
  display: flex;
  background: #892cdc;
  border-radius: 5rem;
  margin: 8rem 20rem 0 0;
  right: 0;
  position: absolute;
  padding: 0.3rem 0.3rem 0.375rem 0.375rem;
}

.band1 {
  border-radius: 5rem;
  padding: 0.3rem;
  margin-right: 0.225rem;
  transition: all 300ms ease-in-out;
}

.band1.active {
  background: rgb(255, 255, 255);
}

.banders2 {
  display: flex;
  background: #ffffff;
  border-radius: 5rem;
  margin: 8rem 20rem 0 0;
  right: 0;
  position: absolute;
  padding: 0.3rem 0.3rem 0.375rem 0.375rem;
}

.band2 {
  border-radius: 5rem;
  padding: 0.3rem;
  margin-left: 0.4rem;
  transition: all 300ms ease-in-out;
}

.band2.active {
  background: #892cdc;
}

@media (max-width: 1000px) {
  .intro h2 {
    margin-top: 5rem;
    font-size: 3.5rem;
  }
  
  .intro h1 {
    font-size: 5.6rem;
  }

  .pages {
    margin-bottom: 7rem;
  }

  .sec-pages h3 {
    font-size: 1.4rem;
  }

  p {
    font-size: 1.4rem;
  }

  .logoSp {
    position: absolute;
    margin: 5.6rem 0 0 8.4rem;
  }

  .hamburger {
    margin: 5.6rem 8.4rem 0 0;
  }

  .banders {
    margin: 5.6rem 16rem 0 0;
  }
}

/* 
font-family: 'Advent Pro', sans-serif;
font-family: 'Architects Daughter', cursive;
font-family: 'El Messiri', sans-serif;
font-family: 'Montserrat', sans-serif;
font-family: 'Open Sans', sans-serif;
font-family: 'Philosopher', sans-serif;
font-family: 'Poppins', sans-serif;
*/