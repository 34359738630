.menuShow {
    display: flex;
    color: white;
    align-items: center;
    width: 100%;
    height: 100%;
}

.menuShow ul {
  margin-top: 5rem;
  list-style: none;
  text-decoration: none;
  margin-left: 15rem;
}

.menuShow ul li,
ul li a {
  font-family: 'Advent Pro', sans-serif;
  color: white;
  font-size: 4rem;
  margin: 1.5rem 0;
  position: relative;
  max-width: fit-content;
  animation: slideInFromLeft 400ms ease-in-out forwards;
  opacity: 0;
  text-decoration: none;
}

.menuShow ul li::after {
  content: "";
  position: absolute;
  bottom: -15%;
  left: 0;
  width: 0;
  height: 0.25rem;
  background-color: white;
  transition: width 0.3s ease;
}

.menuShow ul li:hover::after {
  width: 100%;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.menuShow.open ul li:nth-child(1) {
  animation-delay: 200ms;
}

.menuShow.open ul li:nth-child(2) {
  animation-delay: 500ms;
}

.menuShow.open ul li:nth-child(3) {
  animation-delay: 800ms;
}

.menuShow.open ul li:nth-child(4) {
  animation-delay: 1100ms;
}

.menuShow.open ul li:nth-child(5) {
  animation-delay: 1400ms;
}

@media (max-width: 1000px) {

  .menuShow ul li,
ul li a {
  font-size: 2.8rem;
}

}