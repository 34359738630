.loading-screen {
    z-index: 99999;
}

.iconLoading {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 2rem #892cdc;
    border-radius: 2rem;
}

.iconLoading .lg-loading {
    width: 20%;
    transition: all ease-in-out 600ms;
}

.iconLoading .lg-sp {
    width: 5%;
    opacity: 1;
    transition: all ease-in-out 600ms;
}