.cards-container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    overflow-y: auto;
}

.cards-container::-webkit-scrollbar {
    width: 0.5rem;
    background-color: rgba(255, 255, 255, 0);
    border-radius: 2rem;
}

.cards-container::-webkit-scrollbar-thumb {
    background-color: #892cdc;
    border-radius: 2rem;
}

.cards-projects {
    display: flex;
    width: 90%;
    height: 100%;
    margin-top: 5rem;
    flex-direction: column;
}

.pack-projects {
    display: flex;
    justify-content: space-around;
    margin-bottom: 4rem;
}

.project {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 25rem;
    position: relative;
    transition: transform ease-in-out 200ms;
    flex-direction: column;
}

.project:hover {
    transform: scale(1.2);
    z-index: 10;
}

.project img {
    width: 90%;
    height: auto;
    z-index: 0;
}

.project-name {
    display: flex;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    width: auto;
    width: 90%;
    height: 100%;
    z-index: 1;
    align-items: center;
    justify-content: center;
    border-bottom: solid 0.3rem #892cdc;
}

.project div span {
    font-family: 'Advent Pro', sans-serif;
    color: white;
    font-size: 2.5rem;
    text-align: center;
    max-width: 80%;
    font-weight: 600;
}

.dropdown-content {
    display: none;
    position: absolute;
    width: 100%;
    height: 0;
    z-index: 100;
    bottom: -14%;
    justify-content: space-between;
    border-top: solid 0.3rem #892cdc;
    transition: all 300ms ease-in-out;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    align-items: center;
}

.dropdown-content h5 {
    font-family: 'Advent Pro', sans-serif;
    font-size: 1.5rem;
    font-weight: bold;
    text-wrap: nowrap;
    margin-left: 2rem;
    color: #000000;
}

.project:hover .dropdown-content {
    display: flex;
    width: 90%;
    background-color: rgba(240, 248, 255, 0.6);
    height: 13%;
    transform: translateY(0);
}

.project:hover .project-name {
    display: none;
}

.see-more {
    padding: 0 2rem;
    font-family: 'Advent Pro', sans-serif;
    align-items: center;
    background-color: #892cdc;
    border: 0;
    border-radius: 5rem;
    box-sizing: border-box;
    color: #ffffff;
    display: inline-flex;
    font-size: 1.2rem;
    font-weight: bold;
    justify-content: center;
    line-height: 2rem;
    height: 80%;
    text-align: center;
    vertical-align: middle;
    margin-right: 2rem;
}

.see-more:hover,
.see-more:focus {
    background-color: #59099e;
    color: #ffffff;
}

.see-more:active {
    background: #892cdc;
    color: rgb(255, 255, 255, .9);
}

.background-container {
    z-index: 9999;
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    background-color: rgba(255, 255, 255, 0.05);
    transform: translate(-50%, -50%);
    backdrop-filter: blur(0.5rem);
  }

  .project-container {
    z-index: 99999;
    display: flex;
    position: absolute;
    width: 70%;
    height: 70%;
    top: 50%;
    left: 50%;
    background-color: rgba(255, 255, 255, 0.6);
    transform: translate(-50%, -50%);
    border-radius: 2rem;
    backdrop-filter: blur(0.5rem);
    box-shadow: 0.2rem 0.2rem 0.5rem #892cdc;
  }

  .project-left {
    display: flex;
    width: 60%;
    height: 90%;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: 2rem 2rem;
  }

  .video-project {
    position: relative;
    width: 100%;
    height: 60%;
    top: 0;
    left: 0;
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  .buttons-project {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .btn-proj {
    font-family: 'Open sans', sans-serif;
    color: #fff;
    font-size: 1.4rem;
    border-radius: 5rem;
    border: none;
    background: #892cdc;
    transition: 100ms;
    box-shadow: 0.5rem 0.5rem 1.5rem #892cdc;
    padding: 0.5rem 2rem;
    appearance: none;
    text-decoration: none;
    margin: 0 1.8rem;
  }
    
  .btn-proj:hover {
    transform: scale(1.1);
    transition: 0.5s;
  }

  .btn-proj:active {
    transform: scale(1);
    transition: 100ms;
  }

  .project-right {
    display: block;
    width: 40%;
    font-family: 'Advent Pro', sans-serif;
    overflow-y: auto;
}

.project-right h1 {
    font-size: 3.5rem;
    margin-top: 4.5rem;
    color: #400275;
    max-width: 95%;
}

.project-right h5 {
    font-size: 1.5rem;
    margin-top: 2rem;
    max-width: 90%;
    text-align: justify;
}

.project-right h4 {
    font-size: 2rem;
    margin: 2rem 0 4rem 0;
    max-width: 90%;
    color: #400275;
}

.project-right::-webkit-scrollbar {
    width: 0.5rem;
    background-color: rgba(255, 255, 255, 0);
    border-radius: 2rem;
}

.project-right::-webkit-scrollbar-thumb {
    background-color: #892cdc;
    border-radius: 2rem;
}

@media (max-width: 1000px) {

    
    .cards-projects {
        margin-top: 3.5rem;
    }
    
    .pack-projects {
        margin-bottom: 2.8rem;
    }
    
    .project {
        max-height: 22rem;
    }
        
    
    .project div span {
        font-size: 1.75rem;
    }
    
    .dropdown-content h5 {
        font-size: 1.05rem;
        margin-left: 1.4rem;
    }
    
    .see-more {
        padding: 0 1.4rem;
        font-size: 0.84rem;
        line-height: 1.4rem;
        margin-right: 1.4rem;
    }

      .project-left {
        margin: 1.4rem 1.4rem;
      }
    
      .btn-proj {
        font-size: 0.98rem;
        padding: 0.35rem 1.4rem;
        margin: 0 1.26rem;
      }
    
    .project-right h1 {
        font-size: 2.45rem;
        margin-top: 3.15rem;
    }
    
    .project-right h5 {
        font-size: 1.05rem;
        margin-top: 1.4rem;
    }
    
    .project-right h4 {
        font-size: 1.4rem;
        margin: 1.4rem 0 2.8rem 0;
    }
    
    .project-right::-webkit-scrollbar {
        width: 0.35rem;
        border-radius: 1.4rem;
    }
    }