.fadeIn {
  opacity: 0;
  transition: opacity 5000ms;
}

.fadeIn-active {
  opacity: 1;
}

.section-trophies {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.08);
  border-radius: 1rem;
  padding: 0 2rem;
}

.trophies1 {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.text-divider {
  width: 100%;
}

.description {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 2.5rem;
  color: #892cdc;
  font-size: 1.8rem;
  font-family: 'Advent Pro', sans-serif;
}

.description::before,
.description::after {
  content: "";
  height: 0.2rem;
  background-color: #892cdc;
  display: block;
  width: 30vh;
}

.galery-trophy {
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.galery-trophy span {
  margin-bottom: 1.8rem;
}

.trophies1 {
  position: relative;
}

.trophies1 img {
  transition: transform 300ms ease-in-out;
  will-change: transform;
  margin: 0 4rem;
}

.trophies1 img:hover {
  transform: scale(2.2);
  position: relative;
  z-index: 1;
  transition-delay: 0s;
}

.trophies1 img:active {
  transform: scale(1.8);
  transition: transform 100ms ease-in-out;
}

.description-container {
  z-index: 1;
  display: flex;
  position: absolute;
  width: 70%;
  height: 70%;
  top: 50%;
  left: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  transform: translate(-50%, -50%);
  border-radius: 2rem;
  backdrop-filter: blur(0.5rem);
}

.close-card {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgb(255, 255, 255);
  margin: 2rem 2rem 0 0;
  font-size: 1.6rem;
  padding: 0.4rem;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.close-card:hover {
  transform: scale(1.2);
  transition: all ease-in-out 100ms;
}

.close-card:active {
  transform: scale(1);
}

.card-container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
}

.card-trophy {
  flex: 1;
  height: 92%;
  background-color: rgba(255, 255, 255, 0.005);
  backdrop-filter: blur(0.5rem);
  border-radius: 2rem;
  margin-left: 2rem;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-right: 2rem;
}

.card-trophy-desc {
  flex: 2;
  height: 92%;
  margin-right: 2rem;
}

.card-trophy-desc h1 {
  margin: 1rem 0 0 5rem;
  font-family: 'Advent Pro', sans-serif;
  font-size: 4.2rem;
}

.rating {
  font-family: 'Advent Pro', sans-serif;
  column-gap: normal;
  margin-top: 3rem;
  margin-left: 5rem;
}

.rating span {
  font-size: 1.5rem;
  font-weight: 600;
}

.counters {
  display: flex;
  font-family: 'Advent Pro', sans-serif;
  margin: 3rem 0 0 5rem;
}

.counters h3 {
  font-weight: 600;
  font-size: 1.5rem;
  margin-right: 10rem;
}

.counters h2 {
  font-size: 4rem;
}

.description-card {
  display: block;
  margin: 3rem 0 0 5rem;
}

.description-card p {
  font-size: 1.5rem;
  min-width: 90%;
  text-align: left;
}

@media (max-width: 900px) {

  .trophies1 img {
    width: 4.5rem;
  }

  .description {
    font-size: 1.26rem;
  }

  .description::before,
  .description::after {
    height: 0.15rem;
    width: 30vh;
  }

  .card-trophy img {
    width: 19.6rem;
  }

  .card-trophy-desc h1 {
    font-size: 2.94rem;
    margin: 0.7rem 0 0 5rem;
  }

  .rating span {
    font-size: 1.05rem;
  }

  .counters h3 {
    font-size: 1.05rem;
  }

  .counters h2 {
    font-size: 2.8rem;
  }

  .description-card p {
    font-size: 1.05rem;
  }

  .rating {
    margin-top: 2.1rem;
  }

  .counters {
    margin: 2.1rem 0 0 5rem;
  }

  .description-card {
    margin: 2.1rem 0 0 5rem;
  }
}