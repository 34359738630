.section-title {
    display: flex;
}

.section-title h1 {
    font-family: 'Advent Pro', sans-serif;
    color: white;
    font-size: 2.8rem;
    margin: 4rem 0 0 6rem;
    position: relative;
    z-index: 1000;
}

.section-title h1::after {
    content: "";
    position: absolute;
    bottom: -15%;
    left: 0;
    width: 100%;
    height: 0.2rem;
    background-color: #892cdc;
    z-index: 1000;
}

.container-recommends {
    display: flex;
    width: 100%;
    justify-content: center;
}

.container-recommendations {
    display: flex;
    background-color: rgba(255, 255, 255, 0.15);
    max-height: 35rem; 
    width: 90%;
    text-align: center;
    margin-top: 4rem;
    border-radius: 2rem;    
    padding-top: 2rem;
    overflow-y: auto;
}

.container-recommendations::-webkit-scrollbar {
    width: 0.5rem;
    background-color: rgba(255, 255, 255, 0);
    border-radius: 2rem;
}

.container-recommendations::-webkit-scrollbar-thumb {
    background-color: #892cdc;
    border-radius: 2rem;
}


.recommendation1 img, .recommendation2 img {
    border-radius: 50%;
    margin: 0 2rem 0 0;
}

.recommend {
    display: flex;
    margin-left: 4rem;
}

.recommendation1, .recommendation2 {
    display: flex;
    text-align: left;
    font-family: 'Advent Pro', sans-serif;
    flex: 1;
    flex-direction: column;
}

.recommendation1 h2, .recommendation2 h2 {
    font-size: 2.5rem;
    color: white;
    width: 90%;
}

.recommendation1 h3, .recommendation2 h3 {
    font-size: 1.9rem;
    color: #892cdc;
    width: 90%;
}

.recommendation1 h4, .recommendation2 h4 {
    font-size: 1.5rem;
    color: white;
    margin-top: 1rem;
    width: 90%;
}

.recommendation1 h5, .recommendation2 h5 {
    margin-top: 2rem;
    font-size: 1rem;
    color: white;
    text-align: left;
    width: 90%;
    margin-bottom: 5rem;
}

.recomende-me {
    font-family: 'Open sans', sans-serif;
    color: #fff;
    font-size: 1.7rem;
    border-radius: 5rem;
    border: none;
    position: absolute;
    background: #fdfdfd00;
    transition: 100ms;
    bottom: 0;
    margin: 0 0 5rem 7rem;
    box-shadow: 0 0 2.5rem #892cdc;
    padding: 1.5rem 2.5rem;
    appearance: none;
    text-decoration: none;
  }
    
  .recomende-me:hover {
    transform: scale(0.9) rotate(3deg);
    transition: 0.5s;
  }

  .recomende-me:active {
    transform: scale(1) rotate(-3deg);
    transition: 100ms;
  }

  @media (max-width: 1000px) {

    .container-recommendations {
        max-height: 20rem; 
        margin-top: 2.8rem;  
        padding-top: 1.4rem;
    }

    .section-title h1 {
        font-size: 1.96rem;
        margin: 2.8rem 0 0 4.2rem;
    }

    .recommendation1 h2, .recommendation2 h2 {
        font-size: 1.75rem;
    }
    
    .recommendation1 h3, .recommendation2 h3 {
        font-size: 1.33rem;
    }
    
    .recommendation1 h4, .recommendation2 h4 {
        font-size: 1.05rem;
        margin-top: 0.7rem;
    }
    
    .recommendation1 h5, .recommendation2 h5 {
        font-size: 0.7rem;
        margin-top: 1.4rem;
        margin-bottom: 3.5rem;
    }

    .recomende-me {
        font-size: 1.19rem;
        margin: 0 0 3.5rem 4.9rem;
        padding: 1.05rem 1.75rem;
      }

      .recommend div img {
        width: 7rem;
      }

      .recommend {
        margin-left: 2.8rem;
      }

      .recommendation1 img, .recommendation2 img {
        border-radius: 50%;
        margin: 0 1.4rem 0 0;
    }
  
  }