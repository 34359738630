  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600&display=swap');

  .bgphoto1in,
  .bgphoto1out,
  .bgphoto2in,
  .bgphoto2out {
    position: absolute;
    right: 0;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .bgphoto1in {
    width: 52%;
    background-image: url('../img/Marina e Silas-552.png');
    background-position: center top;
    opacity: 1;
    transition: opacity 1s ease-in-out;
  }

  .bgphoto1out {
    width: 52%;
    background-image: url('../img/Marina e Silas-552.png');
    background-position: center top;
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }

  .bgphoto2in {
    width: 42%;
    background-image: url('../img/Marina e Silas-143.png');
    background-position: left top;
    opacity: 1;
    transition: opacity 1s ease-in-out;
  }

  .bgphoto2out {
    width: 42%;
    background-image: url('../img/Marina e Silas-143.png');
    background-position: left top;
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }

  .aboutme-container {
    position: absolute;
    display: flex;
    width: 58%;
    left: 0;
    height: 80%;
    margin: 2rem 0 0 2rem;
    flex-direction: column;
    align-items: center;
  }

  .purpleTag {
    font-family: 'Advent Pro', sans-serif;
    font-weight: 800;
    color: #892cdc;
    font-size: 1.6rem;
    position: relative;
    justify-content: space-around;
  }

  .about-options {
    position: relative;
    font-family: 'Advent Pro', sans-serif;
    font-weight: 600;
    margin-top: 5rem;
    display: flex;
    color: white;
    justify-content: space-around;
    font-size: 2.5rem;
    width: 100%;
    height: 10%;
  }

  .about-options span {
    border-radius: 2rem;
  }

  .about-options span:hover {
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.08));
  }

  .active-option::after {
    content: "•";
    position: relative;
    bottom: -60%;
    left: -48%;
  }

  .info-description {
    width: 80%;
    height: 100%;
  }

  .info-description h5 {
    font-weight: 400;
    color: white;
    font-family: 'Advent Pro', sans-serif;
    font-size: 2rem;
    text-align: justify;
    margin-top: 5rem;
  }

  .destaque-am {
    font-weight: 400;
    font-family: 'Advent Pro', sans-serif;
    font-size: 2rem;
    text-align: justify;
    margin-top: 5rem;
    color: #892cdc;
  }

  @media (max-width: 1000px) {
  
    .aboutme-container {
      margin: 1.4rem 0 0 1.4rem;
    }
  
    .purpleTag {
      font-size: 1.12rem;
    }
  
    .about-options {
      margin-top: 3.5rem;
      font-size: 1.75rem;
    }
  
    .info-description h5 {
      font-size: 1.4rem;
      margin-top: 3.5rem;
    }
  
    .destaque-am {
      font-size: 1.4rem;
      margin-top: 3.5rem;
    }

    }