.like-container {
   display: flex;
   flex-direction: column;
   align-items: center;
   text-align: center;
   position: absolute;
   bottom: 0;
   right: 0;
   margin: 0 12rem 12rem 0 ;
}

.like-container h3 {
    font-family: 'Advent Pro', sans-serif;
    font-size: 2rem;
    color: white;
 }

.like-container span {
    display: flex;
    font-size: 10rem;
    color: white;
    transition: all ease-in-out 100ms;
    user-select: none;
}

.like-container span:hover {
    transform: scale(1.2);
}

.like-container span:active {
    transform: scale(1);
    background-color: #892cdc;
    border-radius: 5rem;
    box-shadow: 0 0 1rem #892cdc;
}

.alert-landscaping {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.alert-text {
    display: flex;
    width: 75%;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    font-family: 'Advent Pro', sans-serif;
}

.alert-landscaping h1 {
    color: white;
    font-size: 2.5rem;
    margin-bottom: 5rem;
}

.alert-landscaping h2 {
    color: white;
    font-size: 1.8rem;
    margin-top: 2rem;
}

@media (max-width: 1000px) {

    .like-container span {
        font-size: 7rem;
    }

    .like-container h3 {
        font-size: 1.4rem;
     }
  
  }